const provinces = require("./province.json");
const webConfigs = require("./configs.js");

const { FaWpforms } = require("react-icons/fa6");
const { FaChartBar, FaMedal } = require("react-icons/fa");
const {
  MdOutlineCollectionsBookmark,
  MdOutlineAdminPanelSettings,
} = require("react-icons/md");
const { FiTarget, FiUsers } = require("react-icons/fi");
const { BiCreditCardFront, BiDonateHeart } = require("react-icons/bi");
const { RiStoreLine, RiCouponLine } = require("react-icons/ri");
const { TbCashBanknote } = require("react-icons/tb");
const { IoMdPaper } = require("react-icons/io");
const { RiShoppingBag3Fill } = require("react-icons/ri");

const { Dashboard } = require("../pages/dashboard");
const { Users } = require("../pages/users");
const { Orders } = require("../pages/orders/order");
const { Admins } = require("../pages/admins");
const { Setting } = require("../pages/setting");
const { ReportSale, Winner, Affiliate } = require("../pages/reports");

const share = {
  statuses: {
    PENDING: "pending",
    PAID: "paid",
    APPROVE: "approved",
    CANCEL: "canceled",
    ACTIVE: "active",
    INACTIVE: "inactive",
    USED: "used",
    EXPIRED: "expired",
    OFFLINE: "offline",
  },
  statusesTranslate: {
    approved: "การสั่งซื้อเสร็จสมบูรณ์",
    pending: "รอการแนบสลิปการโอนเงิน",
    paid: "รออนุมัติคำสั่งซื้อ",
    canceled: "ยกเลิกคำสั่งซื้อ",
    active: "พร้อมใช้งาน",
    inactive: "ไม่พร้อมใช้งาน",
    used: "ใช้งานแล้ว",
    expired: "หมดอายุ",
    offline: "เปิดใช้งานหลังบ้านเท่านั้น",
  },

  statusColor: {
    approved: "green",
    pending: "yellow",
    paid: "orange",
    canceled: "dangerRed",
    active: "green",
    inactive: "orange",
    used: "blue",
    expired: "red",
    offline: "yellow",
  },

  couponType: {
    DISCOUNT: "discount",
    VOUCHER: "voucher",
    RESERVE: "reserve",
  },

  couponTypeTranslated: {
    discount: "ส่วนลด",
    voucher: "วอยเชอร์",
    reserve: "จองเลขท้าย",
  },

  shippingStatuses: {
    PENDING: "pending",
    PACKING: "packing",
    SHIPPED: "shipped",
    CANCEL: "canceled",
  },
  shippingStatusesTranslate: {
    PENDING: "รอดำเนินการ",
    PACKING: "กำลังเตรียม",
    SHIPPED: "ส่งแล้ว",
    CANCEL: "ยกเลิก",
  },

  deliverChannels: {
    ONLINE: "online",
    MAIL: "mail",
    OFFLINE: "offline",
  },
  paymentChannels: {
    QR: "qr",
    BANK: "bank",
    OFFLINE: "offline",
  },
  stockStatuses: {
    IN_STOCK: "inStock",
    TAKEN: "taken",
  },
  stockStatusesTranslate: {
    IN_STOCK: "อยู่กับบริษัท",
    TAKEN: "ลูกค้ารับแล้ว",
  },
  bankList: [
    { bankName: "ธนาคารกรุงเทพฯ", currency: "THB" },
    { bankName: "ธนาคารไทยพาณิชย์", currency: "THB" },
    { bankName: "ธนาคารกสิกรไทย", currency: "THB" },
    { bankName: "ธนาคารกรุงศรีอยุธยา", currency: "THB" },
    { bankName: "ธนาคารกรุงไทย", currency: "THB" },
    { bankName: "ธนาคารธนชาต", currency: "THB" },
    { bankName: "ธนาคารทิสโก้", currency: "THB" },
    { bankName: "ธนาคารออมสิน", currency: "THB" },
    { bankName: "ธนาคารอาคารสงเคราะห์", currency: "THB" },
    { bankName: "ธนาคารอิสลามแห่งประเทศไทย", currency: "THB" },
    { bankName: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", currency: "THB" },
    { bankName: "ธนาคารทหารไทยธนชาต", currency: "THB" },
    { bankName: "ธนาคารซีไอเอ็มบีไทย", currency: "THB" },
    { bankName: "ธนาคารยูโอบี", currency: "THB" },
    { bankName: "ธนาคารแลนด์ แอนด์ เฮ้าส์", currency: "THB" },
    { bankName: "ธนาคารเกียรตินาคินภัทร", currency: "THB" },
    { bankName: "Joint Development Bank", currency: "LAK" },
    {
      bankName: "Banque Pour Le Commerce Exterieur Lao Public",
      currency: "LAK",
    },

    { bankName: "Lao-Viet Bank Co,Ltd", currency: "LAK" },
    { bankName: "BIC Bank Lao Co., Ltd", currency: "LAK" },
    { bankName: "Lao Development Bank", currency: "LAK" },
    { bankName: "Indochina Bank LTD", currency: "LAK" },
    { bankName: "Maruhan Japan Bank Lao Co.,ltd", currency: "LAK" },
    { bankName: "Agricultural Promotion Bank", currency: "LAK" },
    {
      bankName: "Military Commercial Joint Stock Bank - Laos Branch",
      currency: "LAK",
    },
    { bankName: "KasikornBank in Lao PDR", currency: "LAK" },
    { bankName: "VietinBank Lao limited", currency: "LAK" },
    { bankName: "Acleda Bank Lao Ltd", currency: "LAK" },
    { bankName: "Banque Franco-Lao Ltd", currency: "LAK" },
    { bankName: "Phongsavanh Bank", currency: "LAK" },
    { bankName: "ST Bank LTD", currency: "LAK" },
  ],
};
const constant = {
  zWspc: "​",
  ...share,
  provinces,
  webConfigs,
};

export const banks = [
  "ธนาคารกรุงเทพฯ",
  "ธนาคารไทยพาณิชย์",
  "ธนาคารกสิกรไทย",
  "ธนาคารกรุงศรีอยุธยา",
  "ธนาคารกรุงไทย",
  "ธนาคารธนชาต",
  "ธนาคารทิสโก้",
  "ธนาคารออมสิน",
  "ธนาคารอาคารสงเคราะห์",
  "ธนาคารอิสลามแห่งประเทศไทย",
  "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  "ธนาคารทหารไทยธนชาต",
  "ธนาคารซีไอเอ็มบีไทย",
  "ธนาคารยูโอบี",
  "ธนาคารแลนด์ แอนด์ เฮ้าส์",
  "ธนาคารเกียรตินาคินภัทร",
  "Joint Development Bank",
  "Banque Pour Le Commerce Exterieur Lao Public",
  "Lao-Viet Bank Co,Ltd",
  "BIC Bank Lao Co., Ltd",
  "Lao Development Bank",
  "Indochina Bank LTD",
  "Maruhan Japan Bank Lao Co.,ltd",
  "Agricultural Promotion Bank",
  "Military Commercial Joint Stock Bank - Laos Branch",
  "KasikornBank in Lao PDR",
  "VietinBank Lao limited",
  "Acleda Bank Lao Ltd",
  "Banque Franco-Lao Ltd",
  "Phongsavanh Bank",
  "ST Bank LTD",
];

const lotteries = [
  {
    name: "ลอตเตอรี่ทั้งหมด",
    path: "/lotteries",
    icon: <BiCreditCardFront className="w-6 text-xl" />,
    key: "ลอตเตอรี่",
  },
  {
    name: "ลอตเตอรีหลังบ้าน",
    path: "/lotteries/unimported",
    icon: <BiCreditCardFront className="w-6 text-xl" />,
    key: "ลอตเตอรี่",
  },
  {
    name: "ลอตเตอรี่หน้าบ้าน",
    path: "/lotteries/imported",
    icon: <BiCreditCardFront className="w-6 text-xl" />,
    key: "ลอตเตอรี่",
  },
];

const products = [
  {
    name: "จัดการสินค้า",
    path: "/products/manage",
    icon: <RiStoreLine className="w-6 text-xl" />,
    key: "ร้านค้า",
  },
  {
    name: "คำสั่งซื้อ",
    path: "/products/order",
    icon: <RiStoreLine className="w-6 text-xl" />,
    key: "ร้านค้า",
  },
];

const coupons = [
  {
    name: "จัดการคูปอง",
    path: "/coupons/manage",
    icon: <RiCouponLine className="w-6 text-xl" />,
    key: "คูปอง",
  },
  {
    name: "คำสั่งซื้อ",
    path: "/coupons/order",
    icon: <RiCouponLine className="w-6 text-xl" />,
    key: "คูปอง",
  },
];

const missions = [
  {
    name: "จัดการภารกิจ",
    path: "/mission/manage",
    icon: <FiTarget className="w-6 text-xl" />,
    key: "ภารกิจ",
  },
  {
    name: "ภารกิจสำเร็จทั้งหมด",
    path: "/missions/list",
    icon: <FiTarget className="w-6 text-xl" />,
    key: "ภารกิจ",
  },
];

const donates = [
  {
    name: "จัดการบริจาค",
    path: "/donation/manage",
    icon: <BiDonateHeart className="w-6 text-xl" />,
    key: "บริจาค",
  },
  {
    name: "บริจาคทั้งหมด",
    path: "/donation/list",
    icon: <BiDonateHeart className="w-6 text-xl" />,
    key: "บริจาค",
  },
];

const reports = [
  {
    name: "การขาย",
    path: "/report/sale",
    icon: <IoMdPaper className="w-6 text-xl" />,
    key: "รายงาน",
    component: <ReportSale />,
  },
  {
    name: "ผู้ถูกรางวัล",
    path: "/report/winner",
    icon: <IoMdPaper className="w-6 text-xl" />,
    key: "รายงาน",
    component: <Winner />,
  },
  {
    name: "รายได้ชวนเพื่อน",
    path: "/report/affiliate",
    icon: <IoMdPaper className="w-6 text-xl" />,
    key: "รายงาน",
    component: <Affiliate />,
  },
];

export const paths = [
  {
    name: "แดชบอร์ด",
    path: "/dashboard",
    icon: <FaChartBar className="w-6 text-xl" />,
    component: <Dashboard />,
  },

  ...lotteries,
  // {
  //   name: "คำสั่งซื้อทั้งหมด",
  //   path: "/orders",
  //   icon: <MdOutlineCollectionsBookmark className="w-6 text-xl" />,
  //   key: "ลอตเตอรี่",
  // },
  {
    name: "คำสั่งซื้อ",
    path: "/orders",
    icon: <RiShoppingBag3Fill className="w-6 text-xl" />,
    component: <Orders />,
  },
  {
    name: "คำสั่งซื้อ",
    path: "/orders/:orderId",
    icon: <RiShoppingBag3Fill className="w-6 text-xl" />,
    component: <Orders />,
    hide: true,
  },
  ...products,

  ...coupons,

  ...missions,

  {
    name: "คำขอถอนเงิน",
    path: "/withdraw",
    icon: <TbCashBanknote className="w-6 text-xl" />,
    key: "ถอนเงิน",
  },
  ...donates,
  {
    name: "ลูกค้า",
    path: "/users",
    icon: <FiUsers className="w-6 text-xl" />,
    component: <Users />,
  },

  {
    name: "พนักงาน",
    path: "/admins",
    icon: <MdOutlineAdminPanelSettings className="w-6 text-xl" />,
    component: <Admins />,
  },
  {
    name: "รางวัล",
    path: "/reward",
    icon: <FaMedal className="w-6 text-xl" />,
  },
  ...reports,
  {
    name: "ร้องเรียน",
    path: "/complain",
    icon: <FaWpforms className="w-6 text-xl" />,
    component: <Admins />,
  },
  {
    name: "ตั้งค่า",
    path: "/setting",
    icon: <FaWpforms className="w-6 text-xl" />,
    component: <Setting />,
    hide: true,
  },
];

export default constant;
