import { useState, useEffect } from "react";
import { Loading } from "../../components/modules";
import { Container, Input, Text, Checkbox } from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  useToast,
  Skeleton,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui";

import { FiUserPlus } from "react-icons/fi";
import Context from "../../Context";
import { MdPersonSearch } from "react-icons/md";
import { FaTimes, FaCheckCircle } from "react-icons/fa";
import { paths } from "../../constants";
import { FiShare2 } from "react-icons/fi";

export const Admins = () => {
  const { callApi, isDarkTheme } = Context();
  const { toast } = useToast();
  const [users, setUsers] = useState(false);
  const [user, setUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getUserInfo();
    } else {
      setUser(false);
      setSelectedUser(false);
    }
  }, [selectedUser]);

  const getUserInfo = async () => {
    setUser({ isLoading: true });
    const result = await callApi("users-getUserInfoAdmin", {
      userId: selectedUser,
    });

    setUser(result || false);
  };

  const updateUserInfo = async (userId, key, value) => {
    const { status } = await callApi("users-edit", {
      id: userId,
      key: key,
      value: value,
    });
    getUserInfo();
    return status;
  };

  const fetchUsers = async () => {
    setUsers(false);
    const result = await callApi("users-getAll", {
      queries: [["admin", true]],
      limit: 500,
      orderBy: ["createdAt", "desc"],
    });

    const usersByRole = result.reduce((acc, user) => {
      const { role } = user;

      if (acc[role]) {
        acc[role].push(user);
      } else {
        acc[role] = [user];
      }

      return acc;
    }, {});

    setUsers(result.length > 0 ? usersByRole : false);
    if (result.length === 1) {
      setSelectedUser(result[0].id);
    }
  };

  const handleSearch = () => {
    fetchUsers();
  };

  return (
    <div className="flex gap-3">
      <Container
        className={` p-1 pb-0 h-fit shadow-md w-full duration-150 ${
          selectedUser && "w-[350px]"
        }`}
      >
        <div className="flex items-center gap-2.5 m-2">
          <div className={`relative z-10 flex-grow`}>
            <MdPersonSearch
              className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                isDarkTheme ? "text-white" : " hover:text-[rgb(214,212,212)]"
              } 
              text-input
              `}
              onClick={() => handleSearch()}
            />

            <Input
              className="rounded-2xl"
              placeholder="ค้นหา"
              // value={query.value || ""}
              disabled={true}
              // onChange={(e) =>
              //   setQuery({
              //     key: new RegExp(/^\d+$|^$/).test(query.value)
              //       ? query.value.length >= 6
              //         ? "phone"
              //         : "userId"
              //       : "firstName",
              //     value: e.target.value,
              //   })
              // }
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <div
            className={`flex items-center p-3 font-bold justify-center h-12 rounded-2xl duration-100 cursor-pointer ${
              isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50"
            } hover:opacity-90`}
          >
            <FiUserPlus />
          </div>
        </div>

        {!users ? (
          <div className="flex justify-center items-center h-72">
            <Loading />
          </div>
        ) : (
          <div className={`relative py-0 mt-4`}>
            <ScrollArea className={`px-3`}>
              <Accordion
                type="multiple"
                defaultValue={[
                  "ceo",
                  "dev",
                  "admin",
                  "scanner",
                  "superAdmin",
                  "superScanner",
                  "undefined",
                ]}
              >
                <AccordionItem value="dev">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>Dev</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.dev?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="superAdmin">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>
                      Super Admin
                    </Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.superAdmin?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="admin">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>Admin</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.admin?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="scanner">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>Scanner</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.scanner?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="accountant">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>บัญชี</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.accountant?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="undefined">
                  <AccordionTrigger>
                    <Text className={`text-base font-semibold`}>อื่นๆ</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`grid ${
                        selectedUser
                          ? "grid-cols-1"
                          : "md:grid-cols-2 xl:grid-cols-4 "
                      } gap-2`}
                    >
                      {users?.undefined?.map((user) => (
                        <UserList
                          userData={user}
                          isDarkTheme={isDarkTheme}
                          key={user.userNo}
                          setSelectedUser={setSelectedUser}
                          isSelectedUser={selectedUser === user.id}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </ScrollArea>
          </div>
        )}
      </Container>

      {selectedUser && (
        <div className="w-full flex flex-col gap-3 flex-grow lg:flex-row">
          <User
            selectedUser={selectedUser || false}
            isDarkTheme={isDarkTheme}
            user={user}
            setSelectedUser={setSelectedUser}
            updateUserInfo={updateUserInfo}
            paths={paths}
            fetchUsers={fetchUsers}
          />
        </div>
      )}
    </div>
  );
};

const User = ({
  selectedUser,
  setSelectedUser,
  user,
  updateUserInfo,
  paths,
  fetchUsers,
}) => {
  const { toast } = useToast();

  const [body, setBody] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { callApi } = Context();

  useEffect(() => {
    setBody(user?.allowPaths || []);
  }, [user]);

  const navbarList = paths.reduce((acc, nav) => {
    if (!nav.key) {
      acc[nav.name] = { name: nav.name, icon: nav.icon, path: nav.path };
    } else if (acc[nav.key]) {
      acc[nav.key].pages.push({ name: nav.name, path: nav.path });
    } else {
      acc[nav.key] = {
        name: nav.key,
        icon: nav.icon,
        pages: [{ name: nav.name, path: nav.path }],
      };
    }

    return acc;
  }, []);

  const updateProfile = async () => {
    setIsLoading(true);
    const result = await updateUserInfo(user?.userId, "allowPaths", body);
    if (result) {
      setIsLoading(false);
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });

      return result;
    }
    setIsLoading(false);
    toast({
      variant: "destructive",
      title: (
        <div className="flex gap-2 items-center">
          <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
        </div>
      ),
    });
  };

  if (!selectedUser || !user) return;

  return (
    <Container className={`relative p-3 h-fit duration-150 w-full`}>
      <div
        className={`flex justify-between flex-col-reverse
         xl:flex-row gap-3`}
      >
        <div className={`flex gap-3 flex-col items-center md:flex-row`}>
          {user?.isLoading ? (
            <Skeleton
              className={`w-24 h-24 duration-250
               rounded-full`}
            />
          ) : (
            <Avatar className={"w-24 h-24 duration-150"}>
              <AvatarImage
                src={user?.profileImage || "https://github.com/shadcn.png"}
              />
              <AvatarFallback>{user?.firstName?.slice(0, 2)}</AvatarFallback>
            </Avatar>
          )}
          {user.isLoading ? (
            <div>
              <Skeleton className="h-4 m-1 w-[250px]" />
              <Skeleton className="h-4 m-1 w-[250px]" />
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-1 items-center md:items-start">
              <div className="flex md:items-center gap-1 2xl:gap-3 flex-col md:flex-row ">
                <Text className={`text-xl sm:text-xl font-bold center`}>
                  {`${user?.firstName} ${user?.lastName}`}{" "}
                </Text>
                <Text className={`text-sm text-center text-gray-400 font-bold`}>
                  #{user?.userId}
                </Text>
              </div>
              <Text className={`text-sm mb-3 text-gray-400 font-bold`}>
                {user?.phone}
              </Text>
              {user.role === "dev" && (
                <button
                  onClick={async () => {
                    const { status } = await callApi(
                      "users-updateUserInfoAdmin",
                      {
                        userId: user.userId,
                        data: { admin: false },
                      }
                    );

                    if (status) {
                      setIsLoading(false);
                      fetchUsers();
                      toast({
                        className: "bg-[rgb(59,176,101)]",
                        title: (
                          <div className="flex gap-2 items-center">
                            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
                          </div>
                        ),
                      });

                      return status;
                    }
                    setIsLoading(false);
                    toast({
                      variant: "destructive",
                      title: (
                        <div className="flex gap-2 items-center">
                          <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
                        </div>
                      ),
                    });
                  }}
                  className={`py-2 px-3 rounded-lg border-0 bg-[#c15b78] text-sm text-white font-semibold hover:opacity-90 duration-150 ${
                    isLoading && "opacity-90"
                  }`}
                >
                  {isLoading ? (
                    <Loading className="w-4 h-4 mx-4" />
                  ) : (
                    "ลบพนักงาน"
                  )}
                </button>
              )}
            </div>
          )}
        </div>
        <div className={`flex gap-3 justify-end `}>
          <>
            <FiShare2
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://admin.hongthongofficial.com/users?${user?.id}`
                );

                toast({
                  title: "คัดลอกสำเร็จ",
                  description: `https://admin.hongthongofficial.com/users?${user?.id}`,
                });
              }}
              className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
            />
          </>

          <FaTimes
            onClick={() => setSelectedUser(false)}
            className="cursor-pointer text-gray-400 duration-150 hover:text-red-600"
          />
        </div>
      </div>

      <div className="my-3">
        <Separator />
      </div>
      <Text
        className="font-semibold cursor-pointer"
        htmlFor={navbarList.แดชบอร์ด.path}
      >
        แดชบอร์ด
      </Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList.แดชบอร์ด.path}
            value={navbarList.แดชบอร์ด.path}
            checked={body?.includes(navbarList.แดชบอร์ด.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.แดชบอร์ด.path])
                : setBody(body?.filter((x) => x !== navbarList.แดชบอร์ด.path));
            }}
          />
          <label
            htmlFor={navbarList.แดชบอร์ด.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            แดชบอร์ด
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">ลอตเตอรี่</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 my-2">
        {navbarList?.ลอตเตอรี่?.pages.map((page) => (
          <div className="flex items-center space-x-2" key={page.path}>
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">คำสั่งซื้อ</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.คำสั่งซื้อ?.path}
            value={navbarList?.คำสั่งซื้อ?.path}
            checked={body?.includes(navbarList?.คำสั่งซื้อ?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.คำสั่งซื้อ.path])
                : setBody(
                    body?.filter((x) => x !== navbarList.คำสั่งซื้อ.path)
                  );
            }}
          />
          <label
            htmlFor={navbarList?.คำสั่งซื้อ?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            คำสั่งซื้อ
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">คูปอง</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        {navbarList?.คูปอง?.pages.map((page) => (
          <div
            className="flex items-center space-x-2 cursor-pointer"
            key={page.path}
          >
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">ร้านค้า</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        {navbarList?.ร้านค้า?.pages.map((page) => (
          <div className="flex items-center space-x-2 " key={page.path}>
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">บริจาค</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        {navbarList?.บริจาค?.pages.map((page) => (
          <div className="flex items-center space-x-2" key={page.path}>
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">ภารกิจ</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        {navbarList?.ภารกิจ?.pages.map((page) => (
          <div className="flex items-center space-x-2" key={page.path}>
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">รางวัล</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.รางวัล?.path}
            value={navbarList?.รางวัล?.path}
            checked={body?.includes(navbarList?.รางวัล?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.รางวัล.path])
                : setBody(body?.filter((x) => x !== navbarList.รางวัล.path));
            }}
          />
          <label
            htmlFor={navbarList?.รางวัล?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            รางวัล
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">ลูกค้า</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.ลูกค้า?.path}
            value={navbarList?.ลูกค้า?.path}
            checked={body?.includes(navbarList?.ลูกค้า?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.ลูกค้า.path])
                : setBody(body?.filter((x) => x !== navbarList.ลูกค้า.path));
            }}
          />
          <label
            htmlFor={navbarList?.ลูกค้า?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            ลูกค้า
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">พนักงาน</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.พนักงาน?.path}
            value={navbarList?.พนักงาน?.path}
            checked={body?.includes(navbarList?.พนักงาน?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.พนักงาน.path])
                : setBody(body?.filter((x) => x !== navbarList.พนักงาน.path));
            }}
          />
          <label
            htmlFor={navbarList?.พนักงาน?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            พนักงาน
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">รายงาน</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        {navbarList?.รายงาน?.pages.map((page) => (
          <div className="flex items-center space-x-2" key={page.path}>
            <Checkbox
              id={page.path}
              value={page.path}
              checked={body?.includes(page.path)}
              onCheckedChange={(e) => {
                e
                  ? setBody([...body, page.path])
                  : setBody(body?.filter((x) => x !== page.path));
              }}
            />
            <label
              htmlFor={page.path}
              className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
            >
              {page.name}
            </label>
          </div>
        ))}
      </div>
      <Text className="font-semibold mt-6">ร้องเรียน</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.ร้องเรียน?.path}
            value={navbarList?.ร้องเรียน?.path}
            checked={body?.includes(navbarList?.ร้องเรียน?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.ร้องเรียน.path])
                : setBody(body?.filter((x) => x !== navbarList.ร้องเรียน.path));
            }}
          />
          <label
            htmlFor={navbarList?.ร้องเรียน?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            ร้องเรียน
          </label>
        </div>
      </div>
      <Text className="font-semibold mt-6">ตั้งค่า</Text>
      <div className="grid grid-cols-1 md:grid-cols-3 my-2 gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id={navbarList?.ตั้งค่า?.path}
            value={navbarList?.ตั้งค่า?.path}
            checked={body?.includes(navbarList?.ตั้งค่า?.path)}
            onCheckedChange={(e) => {
              e
                ? setBody([...body, navbarList.ตั้งค่า.path])
                : setBody(body?.filter((x) => x !== navbarList.ตั้งค่า.path));
            }}
          />
          <label
            htmlFor={navbarList?.ตั้งค่า?.path}
            className="text-sm font-medium leading-none cursor-pointer peer-disabled:opacity-70"
          >
            ตั้งค่า
          </label>
        </div>
      </div>
      <div className="flex items-center gap-2 mt-10">
        <button
          onClick={() => updateProfile()}
          className={`py-2 px-3 rounded-lg border-0 bg-[rgb(59,176,101)] text-white font-semibold hover:opacity-90 duration-150 ${
            isLoading && "opacity-90"
          }`}
        >
          {isLoading ? <Loading className="w-4 h-4 mx-4" /> : "บันทึก"}
        </button>
        <button
          onClick={() =>
            setBody(body?.length < 3 ? paths.map((item) => item.path) : [])
          }
          className="py-2 px-3 rounded-lg border-0 bg-transparent text-grey-600 text-sm font-medium hover:opacity-90 duration-150"
        >
          {body?.length > 3 ? "ไม่เลือกทั้งหมด" : "เลือกทั้งหมด"}
        </button>
      </div>
    </Container>
  );
};

const UserList = ({
  userData,
  isDarkTheme,
  setSelectedUser,
  isSelectedUser,
}) => {
  return (
    <div>
      <div
        className={`flex gap-3 items-center px-3 py-2 rounded-xl duration-100 cursor-pointer ${
          isSelectedUser
            ? isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white "
              : "bg-[rgb(26,29,32)] text-gray-50 "
            : isDarkTheme
            ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
            : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
        }`}
        onClick={() => {
          isSelectedUser
            ? setSelectedUser(false)
            : setSelectedUser(userData.id);
        }}
      >
        <Avatar className={"w-12 h-12"}>
          <AvatarImage
            src={userData.profileImage || "https://github.com/shadcn.png"}
          />
          <AvatarFallback>{userData.firstName.slice(0, 2)} </AvatarFallback>
        </Avatar>
        <div>
          <p className={`text-sm font-semibold w-full xl:max-w-48 truncate`}>
            {`${userData.firstName} ${userData.lastName}`}
          </p>
          <p
            className={`text-sm font-semibold w-full text-gray-400 xl:max-w-48 truncate
      `}
          >
            {userData.phone}
          </p>
          <p
            className={`text-sm font-semibold w-full text-gray-400 xl:max-w-48 truncate
      `}
          >
            {userData.id}
          </p>
        </div>
      </div>
    </div>
  );
};
