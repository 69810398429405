import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { CSVLink } from "react-csv";
import { Loading } from "../../components/modules";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

export const Affiliate = () => {
  const { callApi, configs } = Context();
  const downloadRef = useRef(null);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState(false);
  const [sortedData, setSortedData] = useState(false);
  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    setLoading(true);

    let res = await callApi("affiliates-getAffiliateOrder", {
      roundDate: selectedRoundDate,
    });

    const sortedData = res.data.reduce((acc, order) => {
      const { referrer } = order;
      acc[referrer] ? acc[referrer].push(order) : (acc[referrer] = [order]);

      return acc;
    }, {});

    setLoading(false);
    setData(res.data);
    setSortedData(sortedData);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "Affiliate Id",
      "ออเดอร์",
      "จำนวน (ใบ)",
      "รหัสผู้ซื้อ",
      "รายได้ที่ได้รับ",
    ]);

    await data.map((order, index) => {
      acc.push([
        index + 1,
        order.referrer,
        order.shortId,
        order.size,
        order.userId,
        order.size * 2,
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานรายได้ชวนเพื่อน_งวด_${selectedRoundDate}.csv`);
  };

  return (
    <div className="bg-white w-full mb-24  p-8 rounded-lg drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>

      {/* rounddate select */}
      <div className=" ">
        <div className="mb-3 flex gap-2">
          <div>
            <p className="text-md font-bold">งวด</p>
            <Select
              value={selectedRoundDate}
              onValueChange={(e) => setRoundDate(e)}
            >
              <SelectTrigger className="my-1 w-48 text-md font-medium">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.reverse().map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                      key={roundDate}
                    >
                      {roundDate}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            className="h-12 w-32 bg-gray-950 hover:shadow-xl duration-200  disabled:hover:bg-gray-950  text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleSearch}
          >
            {isLoading ? <Loading className={`h-4 w-4`} /> : "ค้นหา"}
          </button>

          <button
            className={`h-12 w-48 bg-[#37beb0]  text-white hover:shadow-xl duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
              (data.length === 0 || !data) && "hidden"
            }`}
            onClick={() => handleDownload()}
          >
            ดาวน์โหลด
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="h-96 w-full flex justify-center items-center">
          <Loading className={`h-4 w-4`} />
        </div>
      ) : (
        data &&
        (data.length > 0 ? (
          <Table className={`mt-11 w-fit`}>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[60px] text-center">ลำดับ</TableHead>
                <TableHead className={`text-center w-[120px]`}>
                  Affiliate ไอดี
                </TableHead>
                <TableHead className={`text-center w-fit`}>ออเดอร์</TableHead>
                <TableHead className={`text-center w-[100px]`}>
                  จำนวน (ใบ)
                </TableHead>
                <TableHead className="text-center">รหัสผู้ซื้อ</TableHead>
                <TableHead className="text-center  w-[200px]">
                  รายได้ที่ได้รับ (ออเดอร์ละ 2 บาท)
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.entries(sortedData).map(([id, orders], index) => {
                return (
                  <TableRow key={index} className={`align-top`}>
                    <TableCell className="font-medium text-center">
                      {index + 1}
                    </TableCell>
                    <TableCell className={`font-bold text-center`}>
                      {id}
                    </TableCell>
                    <TableCell className={`text-center `}>
                      {orders.map((order, index) => {
                        return <p className="mb-2  w-20"> {order.shortId}</p>;
                      })}
                    </TableCell>
                    <TableCell className={`text-center `}>
                      {orders.map((order, index) => {
                        return (
                          <p className="mb-2 w-20 font-bold"> {order.size}</p>
                        );
                      })}
                    </TableCell>
                    <TableCell className={`text-center `}>
                      {orders.map((order, index) => {
                        return <p className="mb-2 w-20"> {order.userId}</p>;
                      })}
                    </TableCell>
                    <TableCell className="text-center font-bold text-green-500">
                      +{" "}
                      {orders.reduce((acc, order) => {
                        const baseIncome = 2;
                        acc += order.size * baseIncome;
                        return acc;
                      }, 0)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <p className="text-gray-600 text-center text-xl font-semibold py-48">
            ไม่พบคำสั่งซื้อ
          </p>
        ))
      )}
    </div>
  );
};
